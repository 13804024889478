<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.orderNo"
        placeholder="系统支付单号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.platformOrderNo"
        placeholder="支付平台单号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.platform"
        placeholder="支付平台"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in PLATFORM"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-input
        v-model="listQuery.orderId"
        placeholder="支付单Id"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.refundNum"
        placeholder="系统退款单号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.platformRefundNum"
        placeholder="支付平台退款单号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.userkw"
        placeholder="用户关键字(手机号码，姓名，微信昵称)"
        style="width: 250px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.status"
        placeholder="退款状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in REFUND_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">设备退款流水数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="编号"
        width="95"
      ></el-table-column>
      <el-table-column
        label="平台"
        align="center"
        prop="platform"
      >
        <template slot-scope="scope">
          <span>{{ getValue(PLATFORM, scope.row.platform) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="系统支付单号"
        align="center"
        prop="order_num"
      ></el-table-column>
      <el-table-column
        label="系统退款单号"
        align="center"
        prop="refund_num"
      ></el-table-column>

      <el-table-column
        label="退款金额"
        align="center"
        prop="amount"
      ></el-table-column>

      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          <span>{{ getValue(REFUND_STATUS, scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="支付平台交易单号"
        align="center"
        prop="trade_num"
      ></el-table-column>

      <el-table-column
        label="支付平台退款单号"
        align="center"
        prop="trade_refund_num"
      ></el-table-column>

      <el-table-column
        label="备注"
        align="center"
        prop="remark"
      ></el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { formatApiDate } from "@/utils/utils";
import { REFUND_STATUS, PLATFORM, getValue } from "@/utils/enumUtile";
import { getRefundOrderList } from "@/api/financial";

export default {
  computed: {
    REFUND_STATUS() {
      return REFUND_STATUS;
    },
    PLATFORM() {
      return PLATFORM;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        orderNo: "", // 系统支付订单号
        platformOrderNo: "", // 平台支付订单号
        platform: "", //支付平台：1-微信，2-支付宝，3-澳门积分，4-系统积分
        orderId: "", //支付单Id
        refundNum: "", //系统退款单号
        platformRefundNum: "", //支付平台退款单号
        userkw: "", //用户关键字(手机号码，姓名，微信昵称)
        status: "" //状态:1-待支付，2-支付中，3-已支付，4-已作废
      }
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    getValue,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getRefundOrderList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    }
  }
};
</script>
<style scoped lang="scss"></style>
